import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
  } from "antd";
  
  import { ToTopOutlined, UserAddOutlined } from "@ant-design/icons";
  import { Link } from "react-router-dom";
  
  // Images
  import ava1 from "../../assets/images/logo-shopify.svg";
  import ava2 from "../../assets/images/logo-atlassian.svg";
  import ava3 from "../../assets/images/logo-slack.svg";
  import ava5 from "../../assets/images/logo-jira.svg";
  import ava6 from "../../assets/images/logo-invision.svg";
  import face from "../../assets/images/face-1.jpg";
  import face2 from "../../assets/images/corola_01.png";
  import face3 from "../../assets/images/civic_01.png";
  import face4 from "../../assets/images/face-4.jpg";
  import face5 from "../../assets/images/face-5.jpeg";
  import face6 from "../../assets/images/face-6.jpeg";
  import pencil from "../../assets/images/pencil.svg";
  
  
  import { vehiclesApi } from "../../helpers/request.helper";
  import { useEffect, useState } from "react";
  import FormCreateVehicles from "../../components/form/FormCreateVehicles";
  
  const { Title } = Typography;
  
  const formProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  // table code start
  const columns = [
    {
      title: "NOME",
      dataIndex: "name",
      key: "name",
      width: "32%",
    },
  
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "CADASTRADO",
      key: "employed",
      dataIndex: "employed",
    },
  ];
  
  
  function CreateVehicles() {
    console.log("CreateVehicles");
    const onChange = (e) => console.log(`radio checked:${e.target.value}`);
  
    const [vehicles, setVehicles] = useState([]); 
  
    return (
      <>
        <div className="tabled">
        <Card 
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Veiculos"
              > 
              </Card>
              <Card
              bordered={false}
              className="header-solid h-full">
            <FormCreateVehicles />
            </Card>
        </div>
      </>
    );
  }
  
  
  export default CreateVehicles;
  