import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form, Button, Select, message, List, Result } from "antd";
import { SmileOutlined } from '@ant-design/icons';
import { vehiclesApi } from "../../helpers/request.helper";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const { Option } = Select;

function Vehicles() {
  const [loading, setLoading] = useState(true);
  const [vehicleOptionals, setVehicleOptionals] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [insertedOptionals, setInsertedOptionals] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    // Carrega os opcionais disponíveis
    vehiclesApi.get("/vehicles/optionals")
      .then((response) => {
        setVehicleOptionals(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching vehicle optionals:", error);
        setLoading(false);
      });

    // Carrega os opcionais já inseridos
    vehiclesApi.get(`/vehicles/${id}/optionals`) 
      .then((response) => {
        setInsertedOptionals(response.data);
        // Define os opcionais inseridos como selecionados
        const selectedIds = response.data.map((item) => item.id_options);
        setSelectedItems(selectedIds);
      })
      .catch((error) => {
        console.error("Error fetching inserted optionals:", error);
      });
  }, [id]);

  const handleSelectChange = (selectedOptionIds) => {
    // Selecione o item
    setSelectedItems(selectedOptionIds);
  
    // Filtra os IDs de opcionais selecionados que não estão presentes na lista de opcionais inseridos
    const newOptionIds = selectedOptionIds.filter(optionId => !insertedOptionals.some(item => item.id_options === optionId));
  
    // Se não houver novos opcionais a serem inseridos, retorna
    if (newOptionIds.length === 0) {
      return;
    }
  
    // Cria uma lista de novos opcionais a serem inseridos
    const newOptionalsToAdd = newOptionIds.map(optionId => ({ id: optionId }));
  
    // Itera sobre cada ID de opcional selecionado que não está na lista de opcionais inseridos e envia uma solicitação POST individualmente
    newOptionIds.forEach(optionId => {
      vehiclesApi.post("/vehicles/optionals", {
        id_vehicle: id, // id do veículo atual
        id_options: [optionId] // Passa o ID do opcional como um array individual
      })
      .then((response) => {
        // Atualiza a lista de opcionais inseridos após a inserção bem-sucedida
        setInsertedOptionals(prevInsertedOptionals => [...prevInsertedOptionals, { id_options: optionId }]);
        message.success("Opcional inserido com sucesso!");
      })
      .catch((error) => {
        console.error("Error inserting optional:", error);
        message.error("Erro ao inserir o opcional. Por favor, tente novamente.");
      });
    });
  };

  const handleDeselect = (deselectedOptionId) => {
    console.log("Deselected option ID:", deselectedOptionId);
    vehiclesApi.delete(`/vehicles/${id}/optionals/${deselectedOptionId}`)
      .then((response) => {
        // Remove o opcional desselecionado da lista de opcionais inseridos
        setInsertedOptionals(prevInsertedOptionals => prevInsertedOptionals.filter(item => item.id_options !== deselectedOptionId));
        message.success("Opcional removido com sucesso!");
      })
      .catch((error) => {
        console.error("Error removing optional:", error);
        message.error("Erro ao remover o opcional. Por favor, tente novamente.");
      });
  };

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Vehicle Optionals"
          >
            <Form
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              style={{ maxWidth: 800 }}
            >
              <Form.Item label="Opcionais">
                <Select
                  mode="multiple"
                  placeholder="Selecione os opcionais"
                  value={selectedItems}
                  onChange={(selectedOptionIds) => { handleSelectChange(selectedOptionIds); }} // Manipulador de evento para lidar com a mudança de seleção
                  onDeselect={handleDeselect}
                  style={{ width: "100%" }}
                >
                  {vehicleOptionals.map((optional) => (
                    <Option key={optional.id} value={optional.id}>
                      {optional.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <Card bordered={false} className="header-solid mb-24">
        <Row gutter={[24, 24]}>
          <Col span={24} md={24} xl={24}>
            <Result
              icon={<SmileOutlined />}
              title="Parabéns por adicionar os opcionais!"
              extra={<Button type="primary" href={`/uploads/${id}`}>Próximo passo é inserir as imagens</Button>}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default Vehicles;
