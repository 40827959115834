import { useContext } from "react";
import { AppContext } from '../state/App.state';

export const useApp = () => {
    const context = useContext(AppContext);

    return {
        name: "PortalAmx",
        version: "1.0.0",
        description: "PortalAmx",
        ...context
    }
}