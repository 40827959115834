import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Divider, Spin, Result, Carousel, Button } from 'antd';
import { SmileOutlined, CarOutlined, ShoppingCartOutlined, ToolOutlined, SettingOutlined, AuditOutlined, EditFilled } from '@ant-design/icons';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Evaluates from "../Evaluates/evaluates"; // Import the Evaluates component
import Optionals from '../Optionals/optionals'; // Import the Optionals component
import Docs from '../Docs/Docs'; // Import the Docs component
import { formatCurrency } from '../../utils/formatCurrency';
import { formatNumber } from '../../utils/formatNumber';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { UpdateDetailsModal } from "./components/updateDetailsModal";
import { useVehicleDetails } from "../../hooks/vehicles/useDetails";

import style from './styles.module.css'

const { Title } = Typography;

const VehicleDetailsPage = () => {
  const [loading, setLoading] = useState(true);
  const [vehicle, setVehicle] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState([]);
  const { id } = useParams();

  const { loading: updateDetailsLoading, updateDetails } = useVehicleDetails();


  const handleSubmit = async (payload) => {
    try {
      const response = await updateDetails(id, payload)
      setVehicle(prevState => {
        return {
          ...prevState,
          mileage: response?.mileage,
          value: response?.value,
          observation: response?.observation,
        }
      })
    } catch (err) {
      console.error('Erro ao atualizar veículo', err)
    } finally {
      setIsOpen(false)
    }
  }


  useEffect(() => {
    const fetchVehicleDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_VEHICLES_URL}/vehicles/${id}`);
        const data = await response.json();
        setVehicle(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching vehicle details:', error);
        setLoading(false); // Ensure loading is stopped even if there's an error
      }
    };

    fetchVehicleDetails();
  }, [id]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_VEHICLES_URL}/vehicles/${id}/uploads`);
        const data = await response.json();
        const photoUploads = data.filter(upload => upload.type === 'photo');
        setImages(photoUploads.map(upload => `https://vehiclesapi.wandersonla.com.br/uploads/${upload.url}`));
      } catch (error) {
        console.error('Error fetching vehicle images:', error);
      }
    };

    if (vehicle && vehicle.uploads && vehicle.uploads.length > 0) {
      fetchImages();
    }
  }, [id, vehicle]);

  if (loading) {
    return <Spin />;
  }

  if (!vehicle || !vehicle.uploads || vehicle.uploads.length === 0) {
    return (
      <Card bordered={false} className="header-solid mb-24">
        <Row gutter={[24, 24]}>
          <Col span={24} md={24} xl={24}>
            <Result
              icon={<SmileOutlined />}
              title="Veiculo inserido com sucesso!"
            />
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <div>
            <Title level={2} style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <CarOutlined />
                <span style={{ marginLeft: '8px' }}>Informações do Veículo</span>
              </div>

              <Link to={`/uploads/${id}`}>
                <Button
                  icon={<EditFilled style={{ fontSize: '16px' }} />}
                  style={{ marginLeft: '8px' }}
                >
                  Editar
                </Button>

              </Link>
            </Title>

            <Carousel className={style.imageDetails} dotPosition='bottom'>
              {images.map((imageUrl, index) => (
                <img
                  key={index}
                  className={style.imageCar}
                  src={imageUrl}
                  alt={`Vehicle ${index + 1}`}
                />
              ))}
            </Carousel>
          </div>

          <Divider />

          <Title level={2} style={{ display: 'flex', alignItems: 'center' }}>
            <ShoppingCartOutlined />
            <span>{formatCurrency(vehicle?.value || 0)}</span>

            <Button
              shape='circle'
              icon={<EditFilled style={{ fontSize: '16px' }} />}
              style={{ marginLeft: '16px' }}
              onClick={() => setIsOpen(true)}
            >
              Editar
            </Button>
          </Title>
          <div className="site-card-wrapper">
            <Row>
              <Col flex={1}>
                <Card title="Marca" bordered={true}>
                  {vehicle.brand}
                </Card>
              </Col>
              <Col flex={1}>
                <Card title="Modelo" bordered={true}>
                  {vehicle.model}
                </Card>
              </Col>
              <Col flex={1}>
                <Card title="Versão" bordered={true}>
                  {vehicle.version}
                </Card>
              </Col>
              <Col flex={1}>
                <Card title="Fabricação/Ano Modelo" bordered={true}>
                {vehicle.yearManufacture}/{vehicle.yearModel}
                </Card>
              </Col>
              <Col flex={1}>
                <Card title="Placa" bordered={true}>
                  {vehicle.plate}
                </Card>
              </Col>
              <Col flex={1}>
                <Card title="KM" bordered={true}>
                  {formatNumber(vehicle.mileage)}
                </Card>
              </Col>
              <Col flex={1}>
                <Card title="Cor" bordered={true}>
                  {vehicle.colors}
                </Card>
              </Col>
              <Col flex={1}>
                <Card title="Combustível" bordered={true}>
                  {vehicle.fuels}
                </Card>
              </Col>
              <Col flex={1}>
                <Card title="Câmbio" bordered={true}>
                  {vehicle.gear}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Card title="Observacao" bordered={true}>
                  {vehicle.observation}
                </Card>
              </Col>
            </Row>
          </div>

          <Divider />

          <div>
            <Title level={2}>
              <ToolOutlined />
              <span>Avaliação</span>
            </Title>

            <Card bordered={true} className="">
              <Evaluates />
            </Card>
          </div>

          <Divider />

          <div>
            <Title level={2}>
              <SettingOutlined />
              <span>Opcionais</span>
            </Title>

            <Card bordered={true} className="header-solid mb-24">
              <Col xs="24" xl={24}>
                <Optionals />
              </Col>
            </Card>
          </div>

          <Divider />

          <Title level={2} style={{ display: 'flex', alignItems: 'center' }}>
            <AuditOutlined />
            <span>Laudos</span>

            <Link to={`/docs/${id}`}>
              <Button icon={<EditFilled style={{ fontSize: '16px' }} />} style={{ marginLeft: '16px' }}>
                Editar
              </Button>
            </Link>
          </Title>

          <Card bordered={true} className="header-solid mb-24">
            <Col xs="24" xl={24}>
              <Docs vehicleId={id} />
            </Col>
          </Card>
        </Col>
      </Row>

      <UpdateDetailsModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        vehicle={vehicle}
        loading={updateDetailsLoading}
        callback={handleSubmit}
      />
    </div >
  );
};

export default VehicleDetailsPage;
