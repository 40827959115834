import React, { useState, useEffect } from 'react';
import { Button, Form, Input, DatePicker, Select } from 'antd';
import useVehicleCreation from '../../hooks/vehicles/useVehicle';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { vehiclesApi } from '../../helpers/request.helper';
const { TextArea } = Input;

const { Option } = Select;

const FormCreateVehicles = () => {
  const [form] = Form.useForm();
  const { createVehicle, loading } = useVehicleCreation();
  const [brands, setBrands] = useState([]); // Definindo brands como estado
  const [models, setModels] = useState([]); // Definindo models como estado 
  const [versions, setVersions] = useState([]); // Definindo versions como estado
  const history = useHistory(); // Usando o hook useHistory
  const [createdVehicleId, setCreatedVehicleId] = useState(null); // Definindo createdVehicleId como estado


  const handleSubmit = async () => {
    const currentbusinessId = localStorage.getItem("agramax.businessId");
    try {
      // Obter dados do usuário logado (substitua esta parte com a lógica de obtenção do usuário)
      const loggedInUser = {
        businessId: parseInt(currentbusinessId, 10),
        customerId: 101,
      };

      // Obter os dados do formulário
      const formData = await form.validateFields();

      console.log('formData:', formData);

      // Obter apenas o ano de yearModel e yearManufacture
      const yearModel = formData.yearModel ? formData.yearModel.year() : null;
      const yearManufacture = formData.yearManufacture ? formData.yearManufacture.year() : null;

      // Combinar os dados do usuário logado com os dados do formulário
      const vehicleData = {
        operation: 'Operação1',
        codigo: 'COD001',
        status: 'Inativo',
        ...loggedInUser,
        ...formData,
        yearModel,
        yearManufacture,
      };

      // Enviar os dados para a função de criação do veículo
      const response = await createVehicle(vehicleData);
      const vehicleId = response.id; // Recuperando o ID do veículo inserido
      setCreatedVehicleId(vehicleId); // Atualizando o estado com o ID do veículo inserido
      
      // Limpar o formulário após o envio bem-sucedido
      form.resetFields();

      // Redirecionando para a página após a inserção bem-sucedida, passando o ID do veículo como parte da rota
      history.push(`/optionals/${vehicleId}`);
    } catch (error) {
      console.error('Erro ao criar veículo:', error);
    }
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log('search:', value);
  };

  // Função para buscar as modelo de veiculos
  const fechModels = async (brand_id) => {
    try {
      const response = await vehiclesApi.get(`/mmvv/${brand_id}/models`);
      console.log('response:', response.data);
      setModels(response.data); // Atualiza o estado com os dados recebidos da API
    } catch (error) {
      console.error('Erro ao buscar os modelos de veículos:', error);
    }
  }
 
// Função para buscar as versoes de veiculos
  const handleModelsChange = async (model_id) => {
    try {
      const response = await vehiclesApi.get(`/mmvv/${model_id}/versions`);
      setVersions(response.data); // Atualiza o estado com os dados recebidos da API
    } catch (error) {
      console.error('Erro ao buscar as versões de veículos:', error);
    }
  }

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await vehiclesApi.get('/mmvv/brands');
        setBrands(response.data); // Atualiza o estado com os dados recebidos da API
      } catch (error) {
        console.error('Erro ao buscar as marcas de veículos:', error);
      }
    };

    fetchBrands();
  }, []);

  const handleBrandsChange = async (brandId) => {
    try {
      const response = await vehiclesApi.get(`/mmvv/${brandId}/models`);
      setModels(response.data);
    } catch (error) {
      console.error('Erro ao buscar os modelos de veículos:', error);
    }
  }

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  
  return (
    <Form
      form={form}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      style={{
        maxWidth: 800,
      }}
    >
      <Form.Item label="Placa" name="plate" rules={[{ required: true, message: 'Por favor, insira a placa do veículo' }]}>
        <Input maxLength={7}/>
      </Form.Item>
      <Form.Item label="Marca" id="brand" name="brand" rules={[{ required: true, message: 'Por favor, insira a marca do veículo' }]}>
      <Select
          showSearch
          placeholder="Selecione uma marca"
          optionFilterProp="children"
          onChange={handleBrandsChange}
          onSearch={onSearch}
          filterOption={filterOption}
          options={brands.map(({id: value, name: label }) => ({label, value }))}
       />
      </Form.Item>
      <Form.Item label="Modelo" name="model" rules={[{ required: true, message: 'Por favor, insira o modelo do veículo' }]}>
      <Select
          showSearch
          placeholder="Selecione um modelo"
          optionFilterProp="children"
          onChange={handleModelsChange}
          onSearch={onSearch}
          filterOption={filterOption}
          options={models.map(({id: value, name: label }) => ({label, value }))}
        />
      </Form.Item>
      <Form.Item label="Versão" name="version">
      <Select
          showSearch
          placeholder="Selecione um versao"
          optionFilterProp="children"
          onChange={handleModelsChange}
          onSearch={onSearch}
          filterOption={filterOption}
          options={versions.map(({id: value, name: label }) => ({label, value }))}
        />
      </Form.Item>
      <Form.Item label="Ano Fabricação" name="yearManufacture">
        <DatePicker picker="year" placeholder="Fabricação"/>
      </Form.Item>
      <Form.Item label="Ano Modelo" name="yearModel">
        <DatePicker picker="year" placeholder="Modelo" />
      </Form.Item>
      <Form.Item label="Carroceria" name="bodyStyle">
        <Select>
          <Option value="1">Hatch</Option>
          <Option value="2">Sedan</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Câmbio" name="gear">
        <Select>
          <Option value="1">Automático</Option>
          <Option value="2">Manual</Option>
          <Option value="3">Semi-automático</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Cor" name="color">
        <Select>
          <Option value="1">Amarelo</Option>
          <Option value="2">Azul</Option>
          <Option value="3">Bege</Option>
          <Option value="4">Branco</Option>
          <Option value="5">Cinza</Option>
          <Option value="6">Diversas</Option>
          <Option value="7">Cores</Option>
          <Option value="8">Dourado</Option>
          <Option value="9">Laranja</Option>
          <Option value="10">Marron</Option>
          <Option value="11">Prata</Option>
          <Option value="12">Preto</Option>
          <Option value="13">Rosa</Option>
          <Option value="14">Roxo</Option>
          <Option value="15">Verde</Option>
          <Option value="16">Vermelho</Option>
          <Option value="17">Vinho</Option>
          <Option value="18">Violeta</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Portas" name="doors">
        <Select>
          <Option value={1}>1</Option>
          <Option value={2}>2</Option>
          <Option value={3}>3</Option>
          <Option value={4}>4</Option>
          <Option value={5}>5</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Combustível" name="fuel">
        <Select>
        <Option value="1">Á/G</Option>
        <Option value="2">Álcool</Option>
        <Option value="3">Diesel</Option>
        <Option value="4">Elétrico</Option>
        <Option value="5">Gasolina</Option>
        <Option value="6">GNV</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Quilometragem" name="mileage">
        <Input maxLength={6} />
      </Form.Item>
      <Form.Item label="Preço" name="value">
        <Input maxLength={6} />
      </Form.Item>
      <Form.Item label="Obs" name="observation">
      <TextArea showCount maxLength={240} />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
        <Button type="primary" onClick={handleSubmit} loading={loading}>
          Criar novo veículo
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormCreateVehicles;
