import { message } from "antd";
import { useState } from "react"
import { vehiclesApi } from "../../helpers/request.helper";

export const useVehicleDetails = () => {
  const [loading, setIsLoading] = useState(false);

  const updateDetails = async (id, payload) => {
    setIsLoading(true)
    try {
      const response = await vehiclesApi.put(`/vehicles/${id}`, payload)
      message.success('Veículo atualizado com sucesso!')
      return response.data
    } catch (err) {
      console.error('Error ao atualizar os detalhes', err)
      message.error('Erro ao atualizar os detalhes do veículo. Por favor, tente novamente');
      throw err
    } finally {
      setIsLoading(false)
    }
  }

  return { updateDetails, loading }
}

