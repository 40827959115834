import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  message
} from 'antd';
import { vehiclesApi } from "../../helpers/request.helper";
import { useAuth } from '../../hooks/useAuth';
import { UserAddOutlined } from "@ant-design/icons";

const FormCreateUsers = () => {
  const [componentSize, setComponentSize] = useState('default');
  const [form] = Form.useForm();
  const auth = useAuth();

  const [businesses, setBusinesses] = useState([]); // Array.isArray(auth.businessId) ? auth.businessId : null);

  useEffect(async () => {
    if (!businesses) return;

    const {data} = await vehiclesApi.get("/business");

    const options = {};
    data.sort((b1, b2) => b1.city > b2.city).forEach(business => {
      const location = `${business.city}/${business.state}`;

      if (!options[location]) {
        options[location] = {
          label: location,
          options: [],
        };
      }

      options[location].options.push({
        value: business.id,
        label: business.name,
      });

    });

    setBusinesses(Object.values(options));
  }, []);


  const handleSubmit = async () => {
    try {
        const user = form.getFieldsValue();

        console.log('user', user);

        await vehiclesApi.post("/users", {
            ...user,
            "username": user.email,
        });

        // Limpar o formulário após o envio bem-sucedido
        form.resetFields();

        // Exibir mensagem de sucesso
        message.success('Usuário criado com sucesso');

    } catch (error) {
        // Tratar erros aqui, se necessário
        console.error('Erro ao criar usuário:', error);
        message.error('Erro ao criar usuário. Por favor, tente novamente.');
    }
};

 
  return (
    <Form
      form={form}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      initialValues={{
        size: componentSize,
      }}
      size={componentSize}
      style={{
        maxWidth: 800,
      }}
    >
      {businesses
      && 
        <>
          <Form.Item label="Selecione a unidade" name="businessId">
            <Select
              optionFilterProp="label"
              onChange={() => console.log('todo')}
              options={businesses}
              showSearch
            />
          </Form.Item>

          <Divider />
        </>
      }
      <Form.Item label="Nome" name="firstName">
        <Input placeholder="nome" />
      </Form.Item>
      <Form.Item label="Sobrenome" name="lastName">
        <Input placeholder="sobrenome" />
      </Form.Item>
      <Form.Item label="Email" name="email">
        <Input />
      </Form.Item>
      <Form.Item label="CPF" name="document">
        <Input />
      </Form.Item>
      <Form.Item label="Senha" name="password">
        <Input />
      </Form.Item>
      <Form.Item >
        <Button onClick={handleSubmit}><UserAddOutlined /> Criar novo Usuario</Button>
      </Form.Item>
    </Form>
  );
};
export default FormCreateUsers;