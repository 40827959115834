// import axios
import axios from 'axios';

// create a new axios api instance
export const vehiclesApi = axios.create({
    //baseURL: 'https://7b902221-d81f-4281-a5a9-9e7e41081870.mock.pstmn.io'
    baseURL: process.env.REACT_APP_VEHICLES_URL
    });

vehiclesApi.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('agramax.token')}`;
    return config;
});

// create a new axios api interceptor for  403 errors
vehiclesApi.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 403) {
            localStorage.removeItem('agramax.token');
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

// create a new axios api instance for keycloak
export const keycloak = axios.create({
    baseURL: process.env.REACT_APP_KEYCLOAK_URL
    });