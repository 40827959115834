import { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Vehicles from "./pages/Vehicles";
import Details from "./pages/Details";
import Users from "./pages/Users";
import CreateUsers from "./pages/CreateUsers";
import CreateVehicles from "./pages/CreateVehicles";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import Optionals from "./pages/Optionals";
import Evaluates from "./pages/Evaluates";
import SignIn from "./pages/SignIn";
import CreatePublicUsers from "./pages/CreatePublicUsers";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { AppContext } from "./state/App.state";
import { AuthContext, getUserInitialState } from "./state/Auth.state";
import UploadsPhotos from "./pages/UploadsPhotos";
import UploadsDocs from "./pages/UploadsDocs";

const Router = () => {
  const currentToken = localStorage.getItem("agramax.token");

  if (!currentToken) {
    return (
      <Switch>
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/sign-in" exact component={SignIn} />
        <Redirect from="*" to="/sign-in" />
      </Switch>
    );
  }

  return (
    <Switch>
    <Route path="/sign-up" exact component={SignUp} />
    <Route path="/sign-in" exact component={SignIn} />
    <Main>
      <Route exact path="/dashboard" component={Home} />
      <Route exact path="/vehicles" component={Vehicles} />
      <Route exact path="/details/:id" component={Details} />
      <Route exact path="/users" component={Users} />
      <Route exact path="/createusers" component={CreateUsers} />
      <Route exact path="/createpublicusers" component={CreatePublicUsers} />
      <Route exact path="/createvehicles" component={CreateVehicles} />
      <Route exact path="/tables" component={Tables} />
      <Route exact path="/billing" component={Billing} />
      <Route exact path="/rtl" component={Rtl} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/optionals/:id" component={Optionals} />
      <Route exact path="/evaluates/:id" component={Evaluates} />
      <Route exact path="/uploads/:id" component={UploadsPhotos} />
      <Route exact path="/docs/:id" component={UploadsDocs} />
      {/* <Redirect from="*" to="/dashboard" /> */}
    </Main>
  </Switch>
  );
};

function App() {
  const [auth, setAuth] = useState(getUserInitialState());
  return (
    <div className="App">
      <AppContext.Provider value={{setAuth}}>
        <AuthContext.Provider value={auth}>
          <Router />
        </AuthContext.Provider>
      </AppContext.Provider>
    </div>
  );
}

export default App;
