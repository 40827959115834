// Docs.js
import React, { useEffect, useState } from 'react';
import { List, Spin, Card, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const Docs = ({ vehicleId }) => {
  const [loading, setLoading] = useState(true);
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    const fetchDocs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_VEHICLES_URL}/vehicles/${vehicleId}/docs`);
        const data = await response.json();
        setDocs(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching docs:', error);
      }
    };

    fetchDocs();
  }, [vehicleId]);

  if (loading) {
    return <Spin />;
  }

  // Função para formatar a data
const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
  };
  return date.toLocaleDateString('pt-BR', options);
};

  return (
    <Card bordered={false}>
      <List
        itemLayout="horizontal"
        dataSource={docs}
        renderItem={item => (
          <List.Item
            actions={[
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                href={`https://vehiclesapi.portalamx.com.br/uploads/${item.url}`}
                target="_blank"
                key="download"
              >
                Download
              </Button>
            ]}
          >
            <List.Item.Meta
              title={` Laudo ${item.id} -- Criado em: ${formatDate(item.createdAt)}`} // Display formatted date
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default Docs;
