import { useState } from 'react';
import { message } from 'antd';
import { vehiclesApi } from '../../helpers/request.helper';

const useVehicleCreation = () => {
  const [loading, setLoading] = useState(false);

  const createVehicle = async (vehicleData) => {
    setLoading(true);
    try {
      const response = await vehiclesApi.post("/vehicles", vehicleData);
      message.success('Veículo criado com sucesso!');
      return response.data;
    } catch (error) {
      console.error('Erro ao criar veículo:', error);
      message.error('Erro ao criar veículo. Por favor, tente novamente.');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { createVehicle, loading };
};

export default useVehicleCreation;
