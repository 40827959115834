
import {
  Row,
  Col,
  Card,
  Button,
  Descriptions,
  Avatar,
  Upload,
} from "antd";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from "@ant-design/icons";

import BgProfile from "../assets/images/bg-profile.jpg";
import { useAuth } from "../hooks/useAuth";

function Profile() {
  const auth = useAuth();

  console.log("auth", auth);

  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>

      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                {/* <Avatar size={74} shape="square" src={profilavatar} /> */}

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{auth?.name}</h4>
                  <p>Filiado</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
            </Col>
          </Row>
        }
      ></Card>

      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Profile Information</h6>}
            className="header-solid h-full card-profile-information"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <Descriptions>
              <Descriptions.Item label="Nome" span={3}>
                {auth?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Telefone" span={3}>
                 {auth?.phone}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
                {auth?.email}
              </Descriptions.Item>
              <Descriptions.Item label="Unidade" span={3}>
                // TODO - business info
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Profile;
