export const formatCurrency = (value) => {
  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value)
}

export const unformatCurrency = (value) => {
  console.log(value)
  return value ? Number(value.replace(/\D/g, '')) / 100 : 0;

}

