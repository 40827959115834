import React, { useEffect, useState } from "react";
import { Row, Col, Card, Radio, Table, message, Avatar, Typography, Rate, Result, Button } from "antd";
import { Link } from "react-router-dom";
import { SmileOutlined } from '@ant-design/icons';
import { vehiclesApi } from "../../helpers/request.helper";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const { Title } = Typography;

function Evaluates() {
  const [evaluates, setEvaluates] = useState([]);
  const [vehicleEvaluates, setVehicleEvaluates] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    vehiclesApi.get(`/vehicles/${id}/evaluates`).then((response) => {
      setVehicleEvaluates(response.data);
    }).catch(error => {
      console.error("Error fetching vehicles:", error);
      setLoading(false);
    });

    vehiclesApi.get("/evaluates").then((response) => {
      setEvaluates(response.data);
    }).catch(error => {
      console.error("Error fetching vehicles:", error);
      setLoading(false);
    });
  }, []);

  const handleSelectChange = (value) => {
    setLoading(true);
    vehiclesApi.post(`/vehicles/${id}/evaluates`, {
      evaluates: [value] // Passa o ID do opcional como um array individual
    })
    .then((response) => {
      setLoading(false);
      setVehicleEvaluates(response.data);
      message.success("Itens avaliados com sucesso!");
    })
    .catch((error) => {
      console.error("Error inserting Evaluates:", error);
      message.error("Erro ao inserir Itens avaliados . Por favor, tente novamente.");
    });
  };
  
  const columns = [
    {
      title: "Item",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Avaliação",
      key: "rating",
      render: (record) => {
        const currentValue = vehicleEvaluates?.evaluates?.find((item) => item.id === record.id)?.value || 0;
        return <Rate 
          value={currentValue} 
          defaultValue={currentValue} 
          onChange={(value)=>handleSelectChange({id:record.id, value})} 
        />
      },
    },
    // Adicione mais colunas conforme necessário
  ];

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Itens avaliados do Veiculo"
              extra={
                <Radio.Group defaultValue="a">
                  <Radio.Button value="a">Aguardando</Radio.Button>
                  <Radio.Button value="b">Portal</Radio.Button>
                </Radio.Group>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={evaluates}
                  loading={loading}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Card bordered={false} className="header-solid mb-24">
        <Row gutter={[24, 24]}>
          <Col span={24} md={12} xl={6}>
            <Result
              icon={<SmileOutlined />}
              title="Parabéns por adicionar os avaliacoes!"
              extra={<Button type="primary" href={`/docs/${id}`}>DOCS do Veiculo</Button>}
            />
          </Col>
        </Row>
      </Card>
      </div>
    </>
  );
}

export default Evaluates;
