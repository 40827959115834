import { useEffect } from "react"
import { Form, Input, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";

export function UpdateDetailsModal(props) {
  const [form] = Form.useForm();

  const submit = async () => {
    const validateFields = await form.validateFields();

    const payload = {
      value: validateFields.value,
      mileage: validateFields.mileage,
      observation: validateFields.observation,
    }
    props.callback(payload)
  }

  useEffect(() => {
    if (props.vehicle) {
      form.setFieldsValue({
        value: props.vehicle.value,
        mileage: props.vehicle.mileage,
        observation: props.vehicle.observation,
      })
    }
  }, [])

  return (
    <Modal
      visible={props.isOpen}
      onOk={submit}
      onCancel={props.onClose}
      cancelText="Cancelar"
      okText="Confirmar"
      title="Editar detalhes do veículo"
      centered
      okButtonProps={{ loading: props.loading }}
      cancelButtonProps={{ disabled: !!props.loading }}
      closable={!props.loading}
      maskClosable={!props.loading}
    >
      <Form
        form={form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        style={{
          maxWidth: 800,
        }}

      >
        <Form.Item label="Valor" name="value" rules={[{ required: true, message: 'Por favor, insira um valor.' }]}>
          <Input
            inputMode="tel"
            style={{
              width: !props.vehicle.value
                ? "100%"
                : String(props.vehicle).length >= 1
                  ? String(props.vehicle).length * 10
                  : 70
            }}
          />
        </Form.Item>

        <Form.Item label="Quilometragem" name="mileage" rules={[{ required: true, message: 'Por favor, insira um Quilometragem' }]}>
          <Input
            inputMode="tel"
            style={{
              width: !props.vehicle.mileage
                ? "100%"
                : String(props.mileage).length >= 1
                  ? String(props.mileage).length * 14
                  : 70
            }}
          />
        </Form.Item>

        <Form.Item label="Obs" name="observation">
          <TextArea showCount maxLength={240} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

