import { createContext } from "react";

export const getUserInitialState = () => {
    const token = localStorage.getItem("agramax.token");
    if (!token) return null;
    try {
        // TODO: use some jwt lib to decode the token (check exp + validate on keycloak in first execution)
        const jwt = atob(token.split('.')[1]);
        const initialUser = JSON.parse(jwt);
        // set localstorage businessId
        localStorage.setItem('agramax.businessId', initialUser?.businessId);
        return initialUser;
    } catch (error) {
        console.error("Error parsing token", error);
        return null;
    }
};

// criar contexto para o login
export const AuthContext = createContext({});
