import { PlusOutlined, SmileOutlined } from '@ant-design/icons';
import { Modal, Upload, Button, message, Row, Col, Result, Card } from 'antd';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { vehiclesApi } from '../../helpers/request.helper';

const prefix = 'https://vehiclesapi.portalamx.com.br/uploads/';
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UploadsDocs = () => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const { id } = useParams();
  const type = 'docs';

  useEffect(() => {
    async function fetchImages() {
      try {
        const response = await vehiclesApi.get(`/vehicles/${id}/docs`);
        const imageFiles = response.data.map((image, index) => ({
          id: image.id,
          uid: String(index),
          name: image.url.substring(image.url.lastIndexOf('/') + 1),
          status: 'done',
          url: `${prefix}${image.url}`,
        }));
        setFileList(imageFiles);
      } catch (error) {
        console.error('Error fetching images:', error);
        message.error('Error fetching images');
      }
    }

    fetchImages();
  }, [id]);

  const customRequest = async ({ file, onProgress, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    try {
      const response = await vehiclesApi.post(`/vehicles/${id}/docs`, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onProgress({ percent: percentCompleted });
        },
      });

      onSuccess(response.data, file);
    } catch (error) {
      onError(error);
    }
  };

  const handleDelete = async (file) => {
    try {
      await vehiclesApi.delete(`/vehicles/${id}/docs/${file.id}`);
      setFileList(fileList.filter((item) => file.id !== file.id));
      message.success('deleted successfully');
    } catch (error) {
      message.error('Failed to delete');
    }
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Upload
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            customRequest={customRequest}
            onRemove={handleDelete}
          >
            {fileList.length >= 8 ? null : uploadButton}
          </Upload>
        </Col>
      </Row>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
      <Card bordered={false} className="header-solid mb-24">
        <Row gutter={[24, 24]}>
          <Col span={24} md={12} xl={6}>
            <Result
              icon={<SmileOutlined />}
              title="Parabéns por adicionar os DOCS!"
              extra={<Button type="primary" href={`/details/${id}`}>Detalhes do veiculo</Button>}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default UploadsDocs;